exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-index-tsx": () => import("./../../../src/pages/about/index.tsx" /* webpackChunkName: "component---src-pages-about-index-tsx" */),
  "component---src-pages-palette-index-tsx": () => import("./../../../src/pages/palette/index.tsx" /* webpackChunkName: "component---src-pages-palette-index-tsx" */),
  "component---src-pages-projects-font-index-tsx": () => import("./../../../src/pages/projects/font/index.tsx" /* webpackChunkName: "component---src-pages-projects-font-index-tsx" */),
  "component---src-pages-projects-index-tsx": () => import("./../../../src/pages/projects/index.tsx" /* webpackChunkName: "component---src-pages-projects-index-tsx" */),
  "component---src-pages-projects-split-table-index-tsx": () => import("./../../../src/pages/projects/splitTable/index.tsx" /* webpackChunkName: "component---src-pages-projects-split-table-index-tsx" */),
  "component---src-pages-projects-xlsx-index-tsx": () => import("./../../../src/pages/projects/xlsx/index.tsx" /* webpackChunkName: "component---src-pages-projects-xlsx-index-tsx" */),
  "component---src-templates-article-template-tsx": () => import("./../../../src/templates/article.template.tsx" /* webpackChunkName: "component---src-templates-article-template-tsx" */),
  "component---src-templates-articles-template-tsx": () => import("./../../../src/templates/articles.template.tsx" /* webpackChunkName: "component---src-templates-articles-template-tsx" */),
  "component---src-templates-home-page-tsx": () => import("./../../../src/templates/HomePage.tsx" /* webpackChunkName: "component---src-templates-home-page-tsx" */),
  "component---src-templates-journals-template-tsx": () => import("./../../../src/templates/journals.template.tsx" /* webpackChunkName: "component---src-templates-journals-template-tsx" */),
  "component---src-templates-photo-group-template-tsx": () => import("./../../../src/templates/photo.group.template.tsx" /* webpackChunkName: "component---src-templates-photo-group-template-tsx" */),
  "component---src-templates-photos-list-template-tsx": () => import("./../../../src/templates/photos.list.template.tsx" /* webpackChunkName: "component---src-templates-photos-list-template-tsx" */)
}

